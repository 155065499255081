<template>
  <Guest>
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-2xl leading-9 font-extrabold text-white uppercase">
        <i18n-t keypath="register.questions.title" />
      </h2>
    </div>
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <QuestionWithOptions v-if="currentQuestion" v-bind:question_container="currentQuestion" v-model:value="currentAnswerId"></QuestionWithOptions>
      </div>
      <div class="mt-6">
        <span @click="goToPreviousStep" class="inline-block w-5/12 sm:w-3/12 rounded-md shadow-sm">
            <button type="button" class="button-transparent w-full">
                <i18n-t keypath="general.back" />
            </button>
        </span>
        <span @click="goToNextStep" class="inline-block w-5/12 rounded-md shadow-sm float-right">
            <button type="button" class="button w-full">
                <i18n-t keypath="general.next" />
            </button>
        </span>
      </div>
    </div>
  </Guest>
</template>

<script>
import { getWalkthroughQuestions } from "@/helper/api/RegistrationHelper";
import { answer_to_store } from "@/helper/store/walkthrough_to_storage";
import Guest from "@/wrappers/Guest";
import QuestionWithOptions from "@/components/forms/QuestionWithOptions";
import { isset } from "@/helper/javascript/hurrrrrdurrrrr";
import i18n from "@/i18n";
const $t = i18n.global.t;


export default {
    name: "SportQuestionsSteps",
    components:{
        Guest,
        QuestionWithOptions
    },
    computed:{
        isLastQuestion: function(){
            const current_ans_obj = this.currentAns;
            if (isset(current_ans_obj) && current_ans_obj.next_question_id === null){
                return true;
            }
            return false;
        },
        nextQuestionId: function(){
            const current_ans_obj = this.currentAns;
            if (! isset(current_ans_obj)){
                return null;
            }
            return current_ans_obj.next_question_id;
        },
        previousQuestionId: function(){
            return null;
        },
        currentQuestion: function (){
            return this.questions.find(q => q.id == this.$route.params.id);
        },
        currentAns: function(){
            if (this.currentAnswerId === null){
                return null;
            }
            return this.currentQuestion.options.find(option => option.id === this.currentAnswerId);
        },
        currentAnswerId: answer_to_store(),
    },
    created(){
        // ToDo: save questions in vuex
        getWalkthroughQuestions()
            .then(data => {
                this.questions = data;
                // console.log('refreshing questions:' , data);
            })
            .catch(err => {
                console.error(err);
            });

        window.addEventListener('beforeunload', this.beforeWindowUnload);
    },
    beforeUnmount() {
        window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
    data(){
        return {
            questions: [],
        }
    },
    methods: {
        goToNextStep: function(){
            if(!this.isLastQuestion){
                if (this.nextQuestionId){
                    this.$router.push({name: 'register.steps.questions', params: {id: this.nextQuestionId}})
                }
            } else {
                // No next sportsquestion; to the wrapup page
                this.$router.push({name: 'register.steps.wrapup'});                
            }
        },
        goToPreviousStep: function(){
            this.$router.go(-1);
        },
        confirmLeave() {
            return window.confirm($t('register.confirmLeave'))
        },
        beforeWindowUnload(e) {
            if (!this.confirmLeave()) {
                // Cancel the event
                e.preventDefault()
                // Chrome requires returnValue to be set
                e.returnValue = ''
            }   
        },
    },
    
}
</script>

<style scoped>

</style>
